@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/remixicon/4.2.0/remixicon.css");

:root {
  --menu: 300px;
  --tabs: 0px;
  --upper: 60px;
  --primary: #101828;
  --secondary: #667085;
}

body {
  overflow: hidden;
  font-family: "Inter", sans-serif;
  background: #f4f4f4;
}

html,
body,
#root,
.app {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #333;
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: #004b5a;
  text-decoration: none;
}

.w__full{
  width: 100%;
}

.text__left{
  text-align: left;
}

.text__right{
  text-align: right;
}

/* .app {
    transform-style: preserve-3d;
} */

.viewer {
  position: relative;
  width: 50%;
  height: 100%;
}

/* .viewport {
    width: 600px;
    height: 600px;
    border: 1px solid #4c4c4c;
    box-sizing: border-box;
} */

.reset {
  position: absolute !important;
  background: #f2f1f1;
  width: 30px;
  height: 30px;
  z-index: 1;
  border-right: 1px solid #444;
  border-bottom: 1px solid #444;
  box-sizing: border-box;
  cursor: pointer;
  left: 0;
  top: 0;
}

.guides {
  position: absolute !important;
  top: 0;
  left: 0;
  transform: translateZ(1px);
}

.guides.horizontal {
  top: 0;
  left: 30px;
  width: calc(100% - 30px);
  height: 30px !important;
  z-index: 99999;
}

.guides.vertical {
  top: 30px;
  left: 0;
  height: calc(100vh - (var(--upper) + 30px));
  width: 30px !important;
  z-index: 99999;
}

.scena-vertical .scena-guide {
  height: calc(100% + 30px) !important;
  top: -30px;
}

.scena-horizontal .scena-guide {
  width: calc(100% + 30px) !important;
  top: -30px;
}

.viewer {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 70px);
}

.viewport {
  height: 100vh;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #333;
  color: #333;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 2px;
}

.rCS1s7qi33 {
  position: absolute;
  /* top: 0; */
  width: 45px;
  height: 100%;
  background: #2a2a2a;
  box-sizing: border-box;
  padding: 15px 7px 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scena-guide {
  position: absolute;
  background: #f33;
  z-index: 2;
}

/* layout-css-start */

.top__bar {
  width: 100%;
  background: #f2f1f1;
  border-bottom: 1px solid #d6d6d6;
}

.main__section {
  display: flex;
}

.sidebar__wrapper {
  width: 100%;
  max-width: var(--menu);
  background-color: #fff;
  height: calc(100vh - var(--upper));
  z-index: 999999;
}

.main__container__wrapper {
  width: 100%;
  max-width: calc(100% - var(--menu));
}

.canvas__container {
  position: relative;
  width: 100%;
  height: calc(100vh - 70px);
}

/* layout-css-end */

/* panel-css-start */

.site__layout .navbar-brand {
  width: 74px;
  background: #f2f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.navbar-brand .logo {
  width: 100%;
  max-width: 44px;
}

.top__bar {
  display: flex;
  align-items: flex-start;
}

.navbar-wrapper {
  width: 100%;
}

.nav-bottom-bar {
  padding: 8px 15px;
  background: #ffffff;
  border-top: 1px solid #d6d6d6;
}

.nav-bottom-bar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.nav--list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  flex: 1 1;
}

.nav--list li {
  text-decoration: none;
  font-weight: 500;
  color: #000;
  font-size: 14px;
  cursor: pointer;
}

.right-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.right-section > * {
  cursor: pointer;
}

.right-section .toggle-button {
  width: 25px;
  height: 20px;
  display: block;
  border-top: 2px solid #4147d5;
  border-bottom: 2px solid #4147d5;
  position: relative;
}

.right-section .toggle-button:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #4147d5;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.sidebar__section {
  display: flex;
}

.side__tab {
  flex: 1;
  border-right: 1px solid #d6d6d6;
}

.side__nav {
  width: 70px;
  background: #f2f1f1;
  height: calc(100vh - var(--upper));
}

.side__nav ul {
  margin: 0;
  padding: 10px 10px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.side__nav ul li > * {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  gap: 10px;
  position: relative;
  cursor: pointer;
}

.side__nav ul li > * label {
  cursor: pointer;
  font-size: 9px;
}

.side__nav ul li > * input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.side__nav ul li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.side__nav ul li a label {
  font-size: 10px;
  color: #656b73;
  padding: 5px 0 0;
  cursor: pointer;
}

.side__wrapper {
  position: relative;
}

.side__wrapper img {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.side__searchbar .side__search__input {
  width: 100%;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #d6d6d6;
  background: #fff;
  padding: 20px 15px 20px 42px;
}

.side__searchbar .side__search__input::placeholder {
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

.side__searchbar .side__search__input:focus {
  outline: 0;
}

.side__tab__listing__section {
  padding: 15px 10px;
}

.side__tab__list__header {
  background: #f1f1f1;
  padding: 10px 8px;
}

.side__tab__list__header__inner {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-start;
  padding: 0 0 10px;
}

.side__tab__listing__section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side__tab__listing__section ul li {
  cursor: pointer;
}

.side__tab__listing__section ul li span {
  padding: 5px 5px;
}

.side__tab__listing__section ul li > * {
  position: relative;
  z-index: 1;
}

.side__tab__listing__section ul li span:not(.active):hover {
  border-radius: 5px;
  background: #efefef;
  box-shadow: 0px -2px 1px 0px rgba(0, 0, 0, 0.05) inset,
    0px 1px 1px 0px #fff inset;
}

.side__tab__listing__section ul li p {
  flex: 1;
}

.side__tab__listing__section ul li > ul {
  padding: 0 0 0 20px;
  position: relative;
}

.side__tab__listing__section ul li > ul:before {
  content: "";
  width: 2px;
  height: calc(100% - 20px);
  position: absolute;
  left: 10px;
  background: #d6d6d6;
}

.side__tab__listing__section ul li > ul li {
  position: relative;
}

.side__tab__listing__section ul li > ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #d6d6d5;
  border-left: 2px solid #d6d6d5;
  border-radius: 0 0 0 6px;
  position: absolute;
  top: 5px;
  margin: auto;
  right: calc(100% - 2px);
}

.side__tab__listing__section ul li > ul li span {
  padding: 5px 5px;
}

.active {
  border-radius: 5px;
  background: #b0b0b0;
  box-shadow: 0px -2px 1px 0px rgba(0, 0, 0, 0.05) inset,
    0px 1px 1px 0px #fff inset;
  color: #000;
}

.side__tab__listing__section ul li span {
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
}

/* panel-css-end */

.right-sidebar {
  position: fixed;
  right: 0;
  width: 100%;
  max-width: var(--menu);
  top: 91px;
  background: #fff;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px;
  justify-content: center;
  background: #f2f1f1;
  border-left: 1px solid #c4c4c4;
}

#racks-contatiner {
  padding: 0;
  width: 100%;
  list-style: none;
}

/* login-css-start */

header nav {
  background: #ffffff;
  padding: 12px 15px !important;
}

.login-section {
  height: calc(100vh - 72px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  width: 100%;
  max-width: 450px;
  margin: auto;
  text-align: center;
}

.login-container h1 {
  color: var(--primary);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.login-container p {
  color: var(--secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.login-form-section {
  padding: 50px 0 0;
}

.login-form-section a {
  display: block;
  text-align: center;
  margin: 10px 0 0;
}

.form__group .form__password {
  position: relative;
}

.form__control {
  display: flex;
  padding: 12px 14px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--White, #fff);
  width: 100%;
  margin: 0 0 10px;
  box-sizing: border-box;
}

span.form__control__icon {
  position: absolute;
  z-index: 100;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 20px;
  height: 20px;
}

span.form__control__icon svg {
  fill: #a5a5a5;
}

.form__control:focus {
  outline: none;
}

.form__control::placeholder {
  color: #818181;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form__group__inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form__checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--primary);
}

.form__checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.forget__password {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--primary);
  text-decoration: none;
}

.btn__primary {
  border-radius: 8px;
  background: #004b5a;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.login-container .btn__primary {
  width: 100%;
  margin: 30px 0 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

label.card {
  position: relative;
  cursor: pointer;
}

label.card > input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.radio:checked ~ .plan-details {
  border: 1px solid #00a0bf !important;
}

.radio:checked ~ .plan-details .checkbox--icon {
  display: flex !important;
}

span.plan-details {
  border-radius: 10px;
  border: 1px solid #f4f4f4;
  background: #fff;
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16px);
  padding: 12px;
  display: block;
  text-align: left;
  position: relative;
}

.checkbox--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #00a0bf;
  position: absolute;
  top: 18px;
  left: 18px;
  display: none;
}

.checkbox--icon::before {
  content: "";
  display: block;
  width: 12px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg);
  margin: 0 0 5px;
}

span.plan-details img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

span.plan-details p {
  color: #000;
  font-weight: 500;
}

/* login-css-end */

.mt-2 {
  margin-top: 30px;
}
.mt-1 {
  margin-top: 15px;
}

.network-stage {
  background-color: #e8e8e8;
  width: 100%;
}

.text-danger {
  display: block;
  text-align: left;
  color: hsla(11, 100%, 42.2%, 1);
  font-size: 14px;
  margin: 0 0 8px;
}

.collapse-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.main__section.left__collapsed {
  --menu: 90px;
}

.main__section.left__collapsed .sidebar__wrapper .collapse-icon {
  margin-right: auto;
  margin-left: 0;
  transform: rotate(180deg);
}

.main__section.left__collapsed .left__sidebar__content {
  display: none;
}

.right__sidebar__wrapper {
  max-width: 300px;
  background-color: #fff;
  width: 100%;
  z-index: 10000;
}

.right__sidebar__wrapper.right__collapsed {
  max-width: 22px;
}

.right__sidebar__wrapper.right__collapsed .collapse-icon {
  transform: rotate(180deg);
}

.right__sidebar__wrapper.right__collapsed .right__sidebar__content {
  display: none;
}

.gird--item--section {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0 0 10px;
}

.gird--item--section > * {
  width: 100%;
  max-width: calc(50% - 5px);
}

.gird--item--section > * .gird--item-content {
  border-radius: 8px;
  border: 1px solid #eaecf0;
}

.gird--item--section-full > * {
  width: 100%;
  max-width: calc(100% - 5px);
}

.gird--item--section-full > * .gird--item-content {
  border-radius: 8px;
  border: 1px solid #eaecf0;
}


.gird--item-content > img {
  width: 30%;
  max-width: 80px;
  display: block;
  margin: 5px auto;
}
.gird--item-content-full > img {
  width: 100%;
  max-width: 150px;
  display: block;
  margin: 5px auto;
}

.gird--item-content > h6 {
  color: #667085;
  font-size: 10px;
  text-align: center;
  margin: 0;
  padding: 8px 0;
  border-top: 1px solid #eaecf0;
  font-weight: 400;
}

.gird--item-content.active--content {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ededed;
}

.gird--item-content.active--content h6 {
  color: #000;
  font-weight: 600;
}

/* modal-css-start */

.site__modal__wrapper {
  position: absolute;
  width: 100%;
  max-width: 90%;
  height: 100%;
  max-height: 90%;
  margin: auto;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100000;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  z-index:999;
}

.site__modal {
  border-radius: 10px;
  border: 1px solid #f4f4f4;
  background: #fff;
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05),
    0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16px);
  padding: 5px;
}

.site__modal__header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  box-sizing: border-box;
}

.site__modal__content {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #f4f4f4;
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: auto;
}

.site__modal__inner__content .controls__button {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.site__modal__inner__content .controls__button > * {
  border-radius: 6px;
  opacity: 0.8;
  background: #fff;
  border: none;
  width: 34px;
  height: 34px;
}

.site__modal__inner__content .rack__image {
  width: 100%;
  max-width: 550px;
  margin: 20px auto;
  display: block;
}

.site__modal__inner__content .socket__image {
  width: 100%;
  max-width: 250px;
  margin: 50px auto;
  display: block;
}

.inline__tab__section ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
  display: flex;
  background: #f6f6f6;
  font-size: 14px;
  font-style: bold;
}
.inline__tab__section_gap ul{
  justify-content: space-around ;
}
.inline__tab__section ul li {
  color: #667085;
  position: relative;
  cursor: pointer;
}

.inline__tab__section ul li::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 7px);
  width: 100%;
  max-width: 95%;
  margin: auto;
  height: 2px;
  background: #00a0bf;
  display: none;
}

.inline__tab__section ul li.inline__tab__active {
  color: #000;
}

.inline__tab__section ul li.inline__tab__active::before {
  display: block;
}

.inline__tab__content__item {
  padding: 20px;
}

.input__label__box > label {
  color: #667085;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  max-width: 80px;
}

.input__label__box .input__label__input_box {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 10px;
}

.input__label__box .input__label__input_box h6 {
  flex: 1;
}

.input__label__box .input__label__input_box .form__control {
  flex: 1;
  margin: 0;
}

.input__label__box {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

/* modal-css-end */

.site__modal__wrapper.category__modal__wrapper {
  grid-template-columns: 1fr;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 60px);
  margin-left: auto;
  margin-right: 0;
}
.site__modal__wrapper.dashboard_common__modal__wrapper {
  grid-template-columns: 1fr;
  max-width: calc(100% - 200px);
  max-height: calc(100% - 100px);
  margin-left: auto;
  margin-right: 100px;
}
.site__modal__wrapper.category__modal__wrapper .site__modal {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  background: #f4f4f4;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.category_radio_card {
  display: flex;
  gap: 20px;
  margin: 30px 0;
  flex-wrap: wrap;
}

.site__modal__content .no_project_found_message_area {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site__modal__content .project-not-found {
  text-align: center;
}
.site__modal__content .project-not-found h2 {
  margin-bottom: 10px;
}

.site__modal__content .project-not-found p {
  margin-top: 15px;
}

.site__modal__content .project-not-found button {
  display: block;
  margin: 10px auto 0;
  width: fit-content;
}

.form-wrapper {
  padding: 20px;
  width: 100%;
  max-width: 750px;
  margin: auto;
  box-sizing: border-box;
}

.form-wrapper h4 {
  margin: 0 0 10px;
}
.form-wrapper-map {
  /* padding: 20px; */
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.form-wrapper-map h4 {
  margin: 0 0 10px;
}

.file__upload {
  border: 2px dashed #ddd;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.file__upload i {
  font-size: 50px;
  color: #ddd;
}

.file__upload input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
}

.file__upload span {
  font-size: 14px;
  color: #888;
  font-weight: 600;
}
.swal2-container{
  z-index: 999999;
}
.global-loader {
  pointer-events: none;
  /* background: rgb(0, 0, 0, 0.3); */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  align-items: center;
  justify-content: center;
}

.form-section-inline {
  display: flex;
  width: 100%;
  gap: 10px;
}

.form-section-inline > * {
  width: 50%;
}

.new--create {
  color: #149fbd;
  font-weight: 600;
  gap: 2px !important;
}

.dropdown-section {
  position: relative;
}

.dropdown-section .dropdown-content {
  position: absolute;
  z-index: 1000000;
  right: 0;
  top: 100%;
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
}

.dropdown-section .dropdown-content.show {
  display: block;
}

.dropdown-section.notification-section .dropdown-content {
  min-width: 320px;
}

.dropdown-section.notification-section .dropdown-content h4 {
  border-bottom: 1px solid #d6d6d6;
  padding: 0 0 5px;
  margin: 0 0 5px;
}

.dropdown-section.notification-section .dropdown-content ul {
  padding: 0 10px 0 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 150px;
  overflow-y: auto;
}

.dropdown-section.notification-section .dropdown-content ul::-webkit-scrollbar {
  width: 3px;
}

.dropdown-section.notification-section
  .dropdown-content
  ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown-section.notification-section
  .dropdown-content
  ul::-webkit-scrollbar-thumb {
  background: #888;
}

.dropdown-section.notification-section .dropdown-content ul li {
  border-bottom: 1px solid #d6d6d6;
  padding: 0 0 10px;
}

.dropdown-section.notification-section .dropdown-content ul li p {
  font-size: 14px;
  margin: 0 0 5px;
}

.dropdown-section.notification-section .dropdown-content ul li span {
  font-size: 12px;
  color: #004b5a;
  font-weight: 500;
}
.s18{
  font-size: 25px;
}

.map-container{
  width: 100%;
  height: 100%;
  position: relative !important;
}

.map-buttons-container {
  background-color: rgb(242 241 241);
  padding: 20px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  z-index: 99999999999;
  display: flex;
}
.marking-stage-buttons-container {
  background-color: rgb(242 241 241);
  padding: 1px;
  position: absolute;
  bottom: 45px;
  left:0;
  right: 0;
  margin: auto;
  width: fit-content;
  z-index: 99999999999;
  display: flex;
}
.info-window-poloygon{
  padding: 10px;
}

.info-window-poloygon h4 {
  margin: 0 0 5px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 5px;
}

.info-window-poloygon ul {
  margin: 10px 0  !important;
  width: 300px;
}

.info-window-poloygon .distance-info{
  padding: 5px 10px;
    margin: 10px 0;
    background: #ddd;
    border-radius: 50px;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
}

.info-window-poloygon .modal-close{
  position: absolute;
    /* background: #ff00002e; */
    border-radius: 50px;
    padding: 5px;
    right: 50px;
    top: 10px;
}

.action-hover:hover,
.action-hover:hover a{
color: #149fbd;
cursor: pointer;
}

.site__modal__category__section {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  margin: auto;
}

label.card:hover  .action-buttons{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.action-buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  background: #00000070;
  z-index: 10;
  border-radius: 20px;
  padding: 20px;
  display: none;

}

.action-buttons i {
  margin: 4px;
  display: inline-block;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
}

.modal-close {
  /* background: #ffebe9; */
  margin: 0 5px;
  padding: 5px;
  border-radius: 50px;
}

.modal-close i, .modal-edit i{
  font-size: 18px;
}

/* .modal-close:hover{
  background: #f44336;
} */
.modal-close:hover i{
  color: #f44336 !important;
  cursor: pointer;
}



/* .modal-edit:hover {
  background-color: #2196F3;
} */

.modal-edit:hover i{
  color: #2196F3;
  cursor: pointer;
}

.modal-edit {
  position: absolute;
  /* background: #7170702e; */
  border-radius: 50px;
  padding: 5px;
  right: 90px;
  top: 10px;
}
.modal-close:hover svg{
  fill:red
}
.form__label{
  font-size: 12px;
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 600;
}

.latlong{
  font-weight: 600;
  font-size: 13px;
}

.image-preview{
  margin: 0 0 10px;
}
.mb-2{
  margin-bottom: 6px;
}

.avatar{
  border: 2px solid transparent;

}
.avatar:hover{
  border: 2px solid #00a0bf;
  border-radius: 100px;
}

.icon-size{
  font-size: 21px;
}

span.site__modal__close__btn{
  text-align: right;
}

/* .canvas-container * {
  width: 100% !important;
} */



.inline__tab__content .btn__primary{
  width: 100%;
  max-width: 109px;
  margin: 10px auto 10px;
}

.site__modal__content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.site__modal__content::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.site__modal__content::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.site__modal__content::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.gird--item.active {
  border: 2px solid #009fbf; /* Example styling */
  background-color: #f0f8ff;
  cursor: pointer;
}
.gird--item:hover{
  cursor: pointer;
}